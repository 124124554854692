<template>
  <q-card
    :bordered="!dark.isActive"
    class="fit"
    flat
    square
  >
    <div class="column fit justify-between">
      <div class="col">
        <nuxt-link
          :to="path"
          :title="`${product.name} for sale at KYGUNCO.`"
          @click="select()"
        >
          <q-img
            :src="thumbnailUrl"
            :alt="`${product.name} for sale at KYGUNCO.`"
            error-src="/placeholder.svg"
            height="200px"
            fit="contain"
            class="bg-white"
            spinner-color="primary"
          >
            <section
              v-if="sale.active"
              class="row absolute-top-left justify-evenly items-center"
            >
              <q-chip
                :label="sale.discount > 15 ? 'Great Deal!' : 'On Sale'"
                color="positive"
                class="text-weight-medium no-border-radius"
                style="box-sizing: border-box;"
                square
              />
            </section>
            <section class="column absolute-top-right q-px-xs">
              <q-avatar
                v-if="product.isQualifiedProfessional"
                :icon="mdiPoliceBadgeOutline"
                text-color="white"
                color="blue-10"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.isClass3"
                :icon="mdiRomanNumeral3"
                text-color="white"
                color="warning"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.isPartnerFulfilled"
                :icon="mdiWarehouse"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.orderType == 'Preorder'"
                :icon="mdiCalendarStar"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.orderType == 'Backorder'"
                :icon="mdiTruckFastOutline"
                text-color="white"
                color="primary"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
              <q-avatar
                v-if="product.hasRebates"
                :icon="mdiCalendarSyncOutline"
                text-color="white"
                color="positive"
                class="q-mt-xs"
                size="28px"
                font-size=".7em"
                square
              />
            </section>
            <div
              v-if="product.averageScore"
              class="row absolute-bottom justify-evenly items-center"
            >
              <div class="row items-center">
                <span class="text-body2 q-mr-sm">
                  {{ rating(product.averageScore) }}
                </span>
                <q-rating
                  :model-value="product.averageScore"
                  :icon-half="mdiStarHalfFull"
                  :icon="!product.averageScore ? mdiStarOutline : undefined"
                  max="5"
                  size="1em"
                  color="secondary"
                  class="q-pa-xs"
                  readonly
                />
              </div>
            </div>
          </q-img>
        </nuxt-link>

        <q-card-section class="q-pb-none">
          <div class="row">
            <div class="col text-caption text-grey ellipsis">
              {{
                product.style.startsWith('SIG-') ? product.mpn : product.style
              }}
            </div>
            <div class="col-3 text-caption text-grey text-right">
              #{{ product.id }}
            </div>
          </div>

          <nuxt-link
            :to="`/product/${product.slug}`"
            :title="product.name"
            style="text-decoration: none; color: inherit"
            @click="select()"
          >
            <div class="text-subtitle2 title ellipsis-2-lines">
              {{ product.name }}
            </div>
          </nuxt-link>
        </q-card-section>
      </div>

      <div class="col-auto">
        <q-card-section class="row items-center justify-around">
          <ProductCardPrice
            :product="product"
            class="q-pa-xs"
          />
          <ProductCardAction :product="product" />
        </q-card-section>

        <div v-if="!product.isClass3 && $credova.valid(product.price)">
          <q-separator />

          <ProductCardFinancing :product="product" />
        </div>
      </div>
    </div>
  </q-card>
</template>

<script lang="ts" setup>
import {
  mdiStarHalfFull,
  mdiStarOutline,
  mdiPoliceBadgeOutline,
  mdiRomanNumeral3,
  mdiCalendarStar,
  mdiTruckFastOutline,
  mdiWarehouse,
  mdiCalendarSyncOutline,
} from '@quasar/extras/mdi-v7';
import type { Product } from '~/types/search';

const props = defineProps({
  product: {
    type: Object as () => Product,
    required: true,
  },
});

const { $credova } = useNuxtApp();
const { dark } = useQuasar();
const { rating } = useFormatting();
const { path, sale, select } = useCatalog(props.product);

const thumbnailUrl = computed(() => {
  if (!props.product.thumbnailUrl?.length) {
    return '/placeholder.svg';
  }

  return useCloudflareImage(props.product.thumbnailUrl, { f: 'auto' });
});
</script>

<style lang="scss" scoped>
.q-img__content > div {
  backdrop-filter: blur(10px);
  padding: 4px;
}

.title {
  font-size: 0.9rem;
}
</style>
