<template>
  <q-card
    :bordered="!dark.isActive"
    class="fit"
    flat
    square
  >
    <div class="column fit justify-between">
      <div>
        <q-responsive style="height: 200px">
          <q-skeleton square />
        </q-responsive>

        <q-card-section class="q-pb-none">
          <div class="row">
            <div class="col">
              <q-skeleton
                type="text"
                square
                width="65%"
              />
            </div>
            <div class="col-3">
              <q-skeleton
                type="text"
                square
              />
            </div>
          </div>

          <q-skeleton
            v-for="line in lines"
            :key="line"
            type="text"
            square
          />
        </q-card-section>
      </div>

      <div>
        <q-card-section class="row items-center justify-around">
          <q-skeleton
            type="rect"
            square
            width="70px"
          />
          <q-skeleton
            type="QBtn"
            square
            width="150px"
          />
        </q-card-section>

        <div v-if="financing">
          <q-item class="q-pa-sm">
            <q-item-section>
              <div class="text-caption">
                <q-skeleton
                  class="q-mx-auto"
                  type="text"
                  width="80%"
                  square
                />
              </div>
            </q-item-section>
          </q-item>
        </div>
      </div>
    </div>
  </q-card>
</template>

<script lang="ts" setup>
defineProps({
  lines: {
    type: Number,
    default: 1,
  },
  financing: {
    type: Boolean,
  },
});

const { dark } = useQuasar();
</script>
