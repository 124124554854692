import { runSequentialPromises, uid } from 'quasar';
import type { Product, SpecialOrder } from '~/types/search';

export const useCatalog = (value: MaybeRefOrGetter<Product>) => {
  const { $bus } = useNuxtApp();
  const product = toValue(value);
  const cartStore = useCartStore();
  const collection = useCollection();
  const {
    ageDialog,
    qpDialog,
    soDialog,
    notifyDialog,
    quoteDialog,
  } = useDialogs();

  const path = computed(() => `/product/${product.slug}`);

  const add = useAsyncData(
    uid(),
    async () => {
      const confirmed = await confirm();

      if (!confirmed) {
        return;
      }

      await cartStore.add({ productId: product.id, quantity: 1 });

      $bus.emit('catalog:add', product, collection);
    },
    { immediate: false },
  );

  const confirm = async (): Promise<boolean> => {
    const dialogs: (() => Promise<void>)[] = [];

    if (product.isAmmunition) {
      dialogs.push(() => ageDialog());
    }

    if (product.isQualifiedProfessional) {
      dialogs.push(qpDialog);
    }

    if (product.orderType == 'Preorder' || product.orderType == 'Backorder') {
      dialogs.push(() => soDialog(product.orderType as SpecialOrder));
    }

    if (!dialogs.length) {
      return true;
    }

    try {
      await runSequentialPromises(dialogs);
    }
    catch {
      return false;
    }

    return true;
  };

  const notify = () => notifyDialog({ productId: product.id }).catch(noop);

  const quote = () => quoteDialog({ productId: product.id }).catch(noop);

  const select = () => $bus.emit('catalog:select', product, collection);

  const sale = computed(() => ({
    active: product.price < product.regularPrice,
    discount:
      ((product.regularPrice - product.price) / product.regularPrice) * 100,
  }));

  const availableInStore = computed(() => product.warehouses.filter(w => ['103', '104'].includes(w)).length > 0);

  return { path, availableInStore, sale, add, confirm, notify, select, quote };
};
