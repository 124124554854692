<template>
  <section>
    <q-btn
      v-if="!visible"
      color="secondary"
      label="Get Price"
      padding="sm md"
      flat
      square
      @click="onClick"
    />
    <div
      v-else
      class="q-px-xs row flex-center q-gutter-x-sm"
    >
      <span
        v-if="sale.active"
        class="text-body2 text-weight-medium bg-positive q-px-xs"
      >
        {{ percent(sale.discount * -1) }}
      </span>
      <span class="text-h6 text-weight-medium price">
        {{ currency(product.price) }}
      </span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useToggle } from '@vueuse/core';
import type { MinimumAdvertisedPricePolicy } from '~/types/ecommerce';
import type { Product } from '~/types/search';

const props = defineProps({
  product: {
    type: Object as () => Product,
    required: true,
  },
});

const { sale } = useCatalog(props.product);
const { currency, percent } = useFormatting();
const { mapDialog } = useDialogs();
const [visible, toggle] = useToggle(!props.product.mapPolicy);

const onClick = () => {
  if (props.product.mapPolicy == null) {
    return;
  }

  if (props.product.mapPolicy == 'Click') {
    toggle(true);
    return;
  }

  mapDialog(props.product.mapPolicy as MinimumAdvertisedPricePolicy);
};
</script>

<style lang="scss" scoped>
.price {
  letter-spacing: normal;
}
</style>
